.nft-detail-modal {
  background: #101010;
}

.nft-modal-body {
  display: flex;
  background: #101010;
  border-radius: 6px;
  border: 1px solid #C8C8C8;
  width: 100%;
}
.nft-modal-container {
  display: flex;
  width: 100%;
  padding: 50px !important;

  .nft-image {
    flex: 2;
    padding: 10px;
    img {
      width: 100%;
      border-radius: 6px;
    }
  }

  .nft-global {
    display: flex;
    flex-direction: column;
    color: #C8C8C8;
    flex: 3;
    padding: 10px;

    .nft-name {
      font-weight: 700;
      font-size: 20px;
      padding: 0 20px;
    }

    .nft-description {
      font-size: 14px;
      padding: 0 20px;
      margin: 20px 0;
    }

    .nft-identifier {
      font-size: 14px;
      padding: 0 20px;
    }
  }
}

.attribute-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  margin: 20px 0;

  .attribute-block {
    display: flex;
    flex-direction: column;
    margin: 10px;
    border: 1px solid #C8C8C8;
    border-radius: 6px;
    padding: 10px;
    width: 130px;
  }

  .attribute-type, .attribute-value {
    width: 100%;
    text-align: center;
  }

  .attribute-type {
    font-weight: 700;
  }
}

@media screen and (max-width: 1000px) {
  .nft-modal-container {
    flex-direction: column;

    .nft-image {
      padding: 20px;
    }
  }
}