.mint-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &-card {
        font-size: 14px;
        padding: 0.5rem;
        border-style: solid;
        border-width: 1px;
        border-radius: 0.375rem;
        color: #c8c8c8;
        font-weight: 800;
        margin: 10px 10px 10px 0;
        min-height: 37px;
    }

    .egld-icon {
        width: 15px;
        margin-left: 4px;
    }
    .whitelist-card {
        color: rgb(119,221,119);
    }
}

