* {
    font-family: 'JetBrains Mono', monospace;
    // font-weight: 600;
}

.pb-10 {
    padding-bottom: 6rem !important;
}


.scroller::-webkit-scrollbar {
    width: 7.5px;
}
  
.scroller::-webkit-scrollbar-track {
    background: red;
}
  
.scroller::-webkit-scrollbar-thumb {
    background: #7f7f7f;
    border-right: 1px solid white;
}

#root, .wrapper, .ape-main-cont, .project-mint {
    overflow: hidden !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}