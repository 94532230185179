.wallet-tokens-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 80px;

  .nft-card-container {
    width: 100%;
    max-width: 240px;
    margin: 15px;
    border-radius: 6px;
    border: 1px solid #C8C8C8;
    cursor: pointer;

    .nft-header-container {
      flex-direction: column;
      text-align: center;
      color: #C8C8C8;
      padding: 10px 0;

      .nft-title {
        font-weight: 700;
        font-size: 16px;
        padding: 5px 0;
      }
    }

    img {
      width: 100%;
      overflow: hidden;
      border-radius: 6px 6px 0 0;
    }
  }
}

.wallet-page-title {
  font-size: 40px;
  color: #C8C8C8;
  margin: 20px 0 30px 0;
}

.collection-title {
  color: #C8C8C8;
  margin: 0 0 20px 0;
  font-size: 24px;
}

.no-nfts-message {
  font-size: 16px;
  color: #C8C8C8;
}

.project-mint-swap {
    width: 100%;
    padding: 10px 0 0 0;
    display: flex;

    button {
        margin: 0 auto;
        background: rgb(38,38,78);
        background: radial-gradient(circle, rgba(38,38,78,1) 40%, rgba(0,0,0,1) 100%);
        border: 0.1px solid rgb(98 98 175) !important;
        box-shadow: 0 0 9px -2px rgb(255 220 220 / 41%);
        width: 50%;

        &:hover {
            border: 0.1px solid #fff !important;
        }
    }
}

.project-mint-swap-old {
    background: rgb(38,38,78);
    background: radial-gradient(circle, rgba(38,38,78,1) 40%, rgba(0,0,0,1) 100%);
    border: 0.1px solid rgb(98 98 175) !important;
    box-shadow: 0 0 9px -2px rgb(255 220 220 / 41%);

    &:hover {
        border: 0.1px solid #fff !important;
    }
}