nav {
    width: 100%;
    //margin: 0 0 auto 0;
  
    .container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  
  
    .dropdown-menu {
      background: #101010;
      padding: 10px;
  
      .dropdown-item {
        color: #747474;
        padding: 2px 0;
      }
  
      .dropdown-item:hover {
        color: #c8c8c8;
        background: transparent;
      }
  
    }
  
    .env {
      font-family: 'Space Mono', monospace !important;
      font-size: 18px;
      color: #c8c8c8;
      padding-left: 10px;
    }
  
    .container-fluid {
      display: flex;
      margin: 10px 0;
      flex-direction: row;
    }
  
    .navbar-brand {
      display: flex;
      width: 100%;
      flex-direction: column;
    }
  
    .logo {
      width: 300px;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: row;
    }
    .nav-item {
      display: flex;
      align-items: center;
      margin: 0 15px;
      a {
        font-family: 'Space Mono', monospace !important;
        text-decoration: none;
        font-size: 18px;
        padding: 0 !important;
        color: #c8c8c8 !important;
      }
    }
  
    .maiar-logo {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  
    .menu-btn {
        display: flex;
        align-items: center;
        background-size: 400%;
        color: #c8c8c8;
        // background: black;
        border: 1px solid #c8c8c8;
        // padding: 12px 30px;
        font-size: 16px;
        background: transparent;
        border-radius: 5px;
        padding: 5px 15px;
    }
  }
  
// Mobile Navbar

.mobile-navbar, .mobile-erd-btn {
    display: none !important;
}


.logout {
font-size: 16px;
margin: 0 0 0 10px;
padding: 5px 10px;
}

.ape-navbar {
    justify-content: end !important;
}
.dropdown-item.active, .dropdown-item:active {
    color: #747474 !important;
    background-color: transparent !important;
}

.navbar-collapse {
    justify-content: end;
}

.menu-btn {
    background: transparent;
    border-radius: 5px;
    padding: 5px 5px;
}

.sidebar-toggle {
    color: #c8c8c8;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    cursor: pointer;
    min-width: 20px;
    min-height: 20px;
}


@media screen and (max-width: 1000px) {
    .container-fluid, .nav-menu {
        flex-direction: column !important;
        margin: auto;

        .nav-item {
        margin: auto;
        padding: 3px 0;
        }
    }
}


@media screen and (max-width: 1200px) {
    nav .logo {
        width: 200px !important;
    }
}



@media screen and (max-width: 665px) {
    .mobile-navbar {
        display: contents !important;
        align-items: center !important;
    }
    .sidebar {
        display: none !important;
    }
    .navbar {
        background: #101010 !important;
    }
    .navbar, .ape-navbar {
        width: 100% !important;
        max-width: none !important;
    }
    .minilogo {
        height: 60px !important;
        width: 50px !important;
    }
    .navbar-nav {
        width: 100% !important;
        flex-direction: row !important;
        justify-content: space-between !important;
        align-items: center !important;
    }
    .navbar-brand {
        display: block !important;
        margin: auto auto auto 0 !important;
    }
    .crop-erd-addr {
        display: none !important;
    }
    .mobile-erd-btn {
        display: unset !important;
    }
}

.nav-fixed-top {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  //position: fixed;
  //top: 0;
  //right: 0;
}