.custom-progress-bar-label {
  padding: 10px 0;
  color: #c8c8c8;
  font-family: "JetBrains Mono", monospace;
  font-weight: 800;
  font-size: 16px;
}

.cstm-progress-bar {
    padding-left: 0 !important;
    padding-right: 0 !important;
}