.project-information {
  display: flex;
  justify-content: space-around;
  width: 100%;

  .project-introduction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 20px;

    .project-title {
      width: 100%;
    }

    .project-description {
      width: 100%;
      color: #C8C8C8;
      margin: 20px 0;
      text-align: justify;
      font-family: "Inter" !important;
      font-size: 15px;
    }

    .project-mint {
      padding: 20px 30px;

      .project-mint-btn {
        width: 100%;
        padding: 10px 0 0 0;
        display: flex;

        button {
            margin: 0 auto;
            background: rgb(38,38,78);
            background: radial-gradient(circle, rgba(38,38,78,1) 40%, rgba(0,0,0,1) 100%);
            border: 0.1px solid rgb(98 98 175) !important;
            box-shadow: 0 0 9px -2px rgb(255 220 220 / 41%);
            width: 50%;

            &:hover {
                border: 0.1px solid #fff !important;
            }
        }
      }

      .progress {
        // box-shadow: 0px 0px 15px 0px rgb(255 97 97);
        // -webkit-box-shadow: 0px 0px 15px 0px rgb(255 97 97);
        height: 0.4rem;
        margin-bottom: 10px;
      }

      .progress .bg-success {
        background-color: #40E0D0 !important;
      }
    }
  }

  .project-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 20px;

    img {
      border-radius: 6px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1090px) {
  .project-information {
    flex-direction: column;
  }

  .project-introduction, .project-image, .project-description {
    // margin: 20px 0 !important;
  }
}