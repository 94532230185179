.team-description {
    display: flex;
    flex-direction: column;

    &-txt {
        font-size: 1.9rem;
        font-weight: 800;
        color: #c8c8c8;

        h1 {
            font-size: 40px;
            font-weight: 700 !important;
        }
    }

    .rainbow_text_animated {
        text-align: initial;
        padding: 0;
    }
    .team-members {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        .team-member {
            img {
                width: 100%;
                max-width: 250px;
                min-width: 200px;
                border-radius: 5px;
                box-shadow: 2px 15px 45px -8px rgba(0,0,0,0.75);
                -webkit-box-shadow: 2px 15px 45px -8px rgba(0,0,0,0.75);
                -moz-box-shadow: 2px 15px 45px -8px rgba(0,0,0,0.75);
            }
    
            &-name {
                font-size: 1.2rem;
                font-weight: 700;
                color: #c8c8c8;
                padding-top: 15px;
            }
            &-nick {
                font-size: 1rem;
                color: #c8c8c8;
            }
        }
    }
}