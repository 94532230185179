.project-socials {
  z-index: 10;
  padding: 10px 10px 10px 0;

  .social-icon {
    color: #FFF;
    font-size: 24px;
    margin: 0 15px 0 0;
  }
}

