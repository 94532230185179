.ape-main-cont {
    margin-left: 265px;
    max-width: calc(100% - 265px) !important;
}

.wrapper {
    display: flex;
    justify-content: center;
    background: -moz-linear-gradient(0deg, rgba(47,47,47,1) 0%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(47,47,47,1) 0%, rgba(0,0,0,1) 100%);
    background: linear-gradient(0deg, rgba(47,47,47,1) 0%, rgba(0,0,0,1) 100%);
}

@media screen and (max-width: 1200px) {
    .ape-main-cont {
        margin-left: 65px !important;
        width: calc(100% - 65px) !important;
    }
    
}

@media screen and (max-width: 665px)  {
    .wrapper {
        display: block !important;
    }
    .ape-main-cont {
        margin-left: 0px !important;
        width: 100% !important;
        min-width: 100% !important;
    }
    .pos-fix {
        position: fixed;
        width: 100%;
    }
}