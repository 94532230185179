.sidebar {
    display: inline-block !important;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    width: 265px;
    z-index: 100;
    padding: 0px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    background: -moz-linear-gradient(0deg, rgba(47,47,47,1) 0%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(47,47,47,1) 0%, rgba(0,0,0,1) 100%);
    background: linear-gradient(0deg, rgba(47,47,47,1) 0%, rgba(0,0,0,1) 100%);
    box-shadow: 24px 1px 34px -24px rgba(0,0,0,0.77);
    -webkit-box-shadow: 24px 1px 34px -24px rgba(0,0,0,0.77);
    -moz-box-shadow: 24px 1px 34px -24px rgba(0,0,0,0.77);

    .navbar-brand {
        width: 100%;
        margin: auto !important;
        padding-bottom: 1rem !important;
    }
}
#sidebar-wrapper{
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}



.nav-item {
  font-family: "Space Mono",monospace !important;
}

.logo {
    width: 100%;
    max-width: 215px;
    margin: auto;
}

.minilogo {
    display: none;
    width: 100%;
    max-width: 50px;
    margin: auto;
}
.rainbow {
    text-align: center;
    text-decoration: underline;
    font-family: monospace;
    letter-spacing: 5px;
}
.sidebar_link {
    display: flex;
    align-items: center;
}
.reduced-navbar {
    padding-left: 30px;
    color: #c8c8c8;
    font-size: 15px;
}
.rainbow_text_animated {
    background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: rainbow_animation 6s ease-in-out infinite;
    background-size: 400% 100%;
    padding-left: 30px;
}


.sidebar_icn {
    width: 20px;
    height: 20px;
    position: absolute;
}

@keyframes rainbow_animation {
    0%,100% {
        background-position: 0 0;
    }

    50% {
        background-position: 100% 0;
    }
}

@media screen and (max-width: 1200px) {
    .sidebar_link {
        justify-content: center !important;
        margin-bottom: 20px;
        
        .sidebar_icn {
            left: 21px;
        }
    }
    .sidebar {
        width: 65px !important;
    }
    .reduced-navbar {
        display: none !important;
    }
    .minilogo {
        display: block;
    }
    .logo {
        display: none;
    }
    .sidebar_icn {
        width: 25px !important;
        height: 25px !important;
    }
}

@media screen and (max-width: 665px) {
    .sidebar-hide {
        transform: translateX(-100%);
        z-index: 998;
        transition: transform 0.3s linear 0s, -webkit-transform 0.3s linear 0s;
    }
    .sidebar-show {
        transform: translateX(0px);
        z-index: 998;
        transition: transform 0.3s linear 0s, -webkit-transform 0.3s linear 0s;
        // position: fixed !important;
        height: 100vh !important;
    }
    .sidebar {
        width: 265px !important;
        margin-top: 82.75px !important;
        padding-top: 15px;

        .reduced-navbar  {
            padding-left: 35px;
        }
        .fire-icon {
            top: 17px !important;
        }
    }
    .reduced-navbar {
        display: unset !important
    }
    .sidebar-mobile-logo {
        display: none !important;
        width: 100%;
        background: red;
    }
}

.menu-item {
    margin: 5px 0;

    .menu-item-icons {
        color: #C8C8C8;
        font-size: 20px;
        margin: 0 10px 0 3px;
    }

    .menu-item-name {
        color: #C8C8C8;
        font-size: 16px;
    }

}