.project-name {
    font-size: 40px;
    color: white;
    text-align: left !important;
}

.project-description {
    width: 100%;
    display: flex;

    .long-description {
        width: 50%;
        color: #c8c8c8;
        text-align: left;
        padding: 20px;

        .rainbow_text_animated {
            text-align: initial;
            padding: 0;
        }

        h3 {
            text-transform: uppercase;
            font-style: italic;
            padding-bottom: 10px;
        }
    }
}

.project-img-overview {

    width: 50%;
    margin: auto;
    padding: 20px;

    img {
        // max-height: 450px;
        // max-width: 450px;
        max-width: 100%;
        height: auto;
        
    }

    &-two {
        img {
            // max-height: 450px;
            max-width: 500px;
            width: 100%;
        }
    }
}

@media screen and (max-width: 1060px) {
    .project-description-two {
        flex-direction: column;

        .long-description {
            text-align: center;
            margin: auto;
            padding-top: 10px;
            width: 100% !important;
        }
        .project-img-overview {
            width: 100% !important;
        }
    }
}

@media screen and (max-width: 1060px) {
    .project-description-one {
        flex-direction: column !important;

        .long-description {
            order: 2;
            margin: auto;
            width: 100% !important;
        }
        .project-img-overview {
            order: 1;
            width: 100% !important;
            min-height: 150px;
        }
    }
    .project-name {
        padding-left: 0px !important;
    }
}

.descr {
    text-align: justify;
    white-space: pre-wrap;
    font-family: "Inter";
    font-size: 15px;
    color: rgba(200, 200, 200, 0.8);
}

.reverse {
    flex-direction: row-reverse;
}

@media screen and (max-width: 300px) {
    .project-description {
        min-height: 310px;
    }
}